import React from "react"

import Layout2 from "../components/layout2"
import SEO from "../components/seo"
import ServicesComponent from "../components/servicescomp"

import Carousel from "../components/carousel"
import BlogComponent from "../components/blogcomp"
import Clients from "../components/clients"

const IndexPage = () => {
  return (
    <Layout2>
      <SEO
        title="Home"
        keywords={[
          `ppasesoriaintegral`,
          `implementacion-iso`,
          `asesoria integral`,
        ]}
      />
      <section className="jumbotron" id="jumbotron" name="jumbotron">
        <div className="container">
          <div className="picture">
            <Carousel />
          </div>
        </div>
      </section>
      <ServicesComponent />

      <section name="clientes" className="clientes">
        <div className="heading">
          <h1>Clientes</h1>
          <h3>Ellos ya confían en nosotros</h3>
        </div>
        <div className="container">
          <Clients />
        </div>
      </section>
      <BlogComponent />
    </Layout2>
  )
}
export default IndexPage
